exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-quiz-js": () => import("./../../../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/BlogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-book-page-js": () => import("./../../../src/templates/BookPage.js" /* webpackChunkName: "component---src-templates-book-page-js" */),
  "component---src-templates-books-page-js": () => import("./../../../src/templates/BooksPage.js" /* webpackChunkName: "component---src-templates-books-page-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/Category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-cookie-policy-page-js": () => import("./../../../src/templates/CookiePolicyPage.js" /* webpackChunkName: "component---src-templates-cookie-policy-page-js" */),
  "component---src-templates-feature-js": () => import("./../../../src/templates/Feature.js" /* webpackChunkName: "component---src-templates-feature-js" */),
  "component---src-templates-form-page-js": () => import("./../../../src/templates/FormPage.js" /* webpackChunkName: "component---src-templates-form-page-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-legal-page-js": () => import("./../../../src/templates/LegalPage.js" /* webpackChunkName: "component---src-templates-legal-page-js" */),
  "component---src-templates-new-front-page-js": () => import("./../../../src/templates/NewFrontPage.js" /* webpackChunkName: "component---src-templates-new-front-page-js" */),
  "component---src-templates-new-pricing-page-js": () => import("./../../../src/templates/NewPricingPage.js" /* webpackChunkName: "component---src-templates-new-pricing-page-js" */),
  "component---src-templates-new-solution-page-js": () => import("./../../../src/templates/NewSolutionPage.js" /* webpackChunkName: "component---src-templates-new-solution-page-js" */),
  "component---src-templates-onboarding-cookie-page-js": () => import("./../../../src/templates/OnboardingCookiePage.js" /* webpackChunkName: "component---src-templates-onboarding-cookie-page-js" */),
  "component---src-templates-onboarding-page-js": () => import("./../../../src/templates/OnboardingPage.js" /* webpackChunkName: "component---src-templates-onboarding-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-price-page-new-js": () => import("./../../../src/templates/pricePageNew.js" /* webpackChunkName: "component---src-templates-price-page-new-js" */),
  "component---src-templates-prices-page-test-js": () => import("./../../../src/templates/PricesPageTest.js" /* webpackChunkName: "component---src-templates-prices-page-test-js" */),
  "component---src-templates-pricing-enterprice-page-js": () => import("./../../../src/templates/PricingEnterpricePage.js" /* webpackChunkName: "component---src-templates-pricing-enterprice-page-js" */),
  "component---src-templates-pricing-reseller-page-js": () => import("./../../../src/templates/PricingResellerPage.js" /* webpackChunkName: "component---src-templates-pricing-reseller-page-js" */),
  "component---src-templates-pricing-reseller-page-new-js": () => import("./../../../src/templates/PricingResellerPageNew.js" /* webpackChunkName: "component---src-templates-pricing-reseller-page-new-js" */),
  "component---src-templates-privacy-policy-page-js": () => import("./../../../src/templates/PrivacyPolicyPage.js" /* webpackChunkName: "component---src-templates-privacy-policy-page-js" */),
  "component---src-templates-public-scanner-js": () => import("./../../../src/templates/PublicScanner.js" /* webpackChunkName: "component---src-templates-public-scanner-js" */),
  "component---src-templates-solution-js": () => import("./../../../src/templates/Solution.js" /* webpackChunkName: "component---src-templates-solution-js" */),
  "component---src-templates-subprocessors-js": () => import("./../../../src/templates/Subprocessors.js" /* webpackChunkName: "component---src-templates-subprocessors-js" */),
  "component---src-templates-technology-js": () => import("./../../../src/templates/Technology.js" /* webpackChunkName: "component---src-templates-technology-js" */),
  "component---src-templates-terms-service-js": () => import("./../../../src/templates/TermsService.js" /* webpackChunkName: "component---src-templates-terms-service-js" */),
  "component---src-templates-thanku-sign-page-js": () => import("./../../../src/templates/ThankuSignPage.js" /* webpackChunkName: "component---src-templates-thanku-sign-page-js" */),
  "component---src-templates-thankyou-page-js": () => import("./../../../src/templates/ThankyouPage.js" /* webpackChunkName: "component---src-templates-thankyou-page-js" */),
  "component---src-templates-video-article-page-js": () => import("./../../../src/templates/VideoArticlePage.js" /* webpackChunkName: "component---src-templates-video-article-page-js" */),
  "component---src-templates-videoblog-page-js": () => import("./../../../src/templates/VideoblogPage.js" /* webpackChunkName: "component---src-templates-videoblog-page-js" */)
}

